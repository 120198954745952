<template>
</template>

<script>
export default {
  name: 'LoginService',
  created() {
    this.$store.commit('setUserData', this.$route.query.token);
    this.$store.commit('setConsumType', true);
    this.$router.replace(`/courseDetails?id=${this.$route.query.id}`);
  }
}
</script>